export default {
    methods: {
        getActsShutters(props) {
            return {
                'act-history': {
                    name: 'act-history',
                    title: this.$t('global.actes'),
                    cmpPath: 'components/Act/History',
                    props
                },
                'act-horse': {
                    name: 'act-horse',
                    title: this.$t('global.actes'),
                    cmpPath: 'components/Act/History',
                    props
                }
            }
        },

        setupActHistory(actualTab='') {
            const shutters = this.getActsShutters({
                actualTab
            })
            this.shutterPanel().open(shutters['act-history'])
        },

        setupHorseAct(horse_id) {
            const shutters = this.getActsShutters({
                horse_id
            })
            this.shutterPanel().open(shutters['act-horse'])
        }
    }
}
