<template>
    <main id="liste" v-cloak>
        <HeaderTab 
            :title="$t('menu.actes.actes')" 
            :swiper_tabs="swiper_tabs" 
            :swiper_action="changeTab" 
        />

        <div id="content">
            <shutter-panel ref="shutterPanel" />
        </div>
    </main>
</template>


<script type="text/javascript">
    import ActeMixin from "@/mixins/Actes.js"
    import Shutter from "@/mixins/Shutter.js"
    import ShutterActs from '@/mixins/shutters-manager/Acts.js'

	export default {
		name: "acteListe",
		mixins: [ActeMixin, Shutter, ShutterActs],
		data () {
			return {
				swiper_tabs: [{
                        label: 'acte.tous',
                        href : 'acteListe',
                        active: true,
                        name: ''
                    }
                ],
				actual_tab: {},
                actes_groupes: []
			}
		},
		created() {
            // On init les données de la vue
            this.init_component()
		},
		methods: {
            async init_component() {
                this.actes_groupes = await this.getActesGroupes(false)

                this.actes_groupes.forEach(acte_groupe => {
                    this.swiper_tabs.push({
                        label: 'acte.'+acte_groupe.actesgroupe_label.toLowerCase(),
                        active: false,
                        name: acte_groupe.actesgroupe_label
                    })
				})

                this.actual_tab = this.swiper_tabs[0]
                this.setupActHistory(this.actual_tab.name)
			},

			async changeTab(tab) {
                this.actual_tab.active = false
				this.actual_tab = tab
                tab.active = true
                this.shutterPanel().update('act-history', 'actualTab', this.actual_tab.name)
			}
		},
		components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab')
		}
	}
</script>